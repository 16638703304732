import { Engine } from "./engine";
import { HslColorManager } from "./Utils/HslColorManager";
import { RgbColorManager } from "./Utils/RgbColorManager";
import { addColorManager } from "./Utils/ColorUtils";
import { isSsr } from "./Utils/Utils";
const rgbColorManager = new RgbColorManager(), hslColorManager = new HslColorManager();
addColorManager(rgbColorManager);
addColorManager(hslColorManager);
const tsParticles = new Engine();
tsParticles.init();
if (!isSsr()) {
    window.tsParticles = tsParticles;
}
export * from "./exports";
export * from "./export-types";
export { tsParticles };
