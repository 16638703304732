import { EventDispatcher } from "./Utils/EventDispatcher";
import { Loader } from "./Core/Loader";
import { Plugins } from "./Core/Utils/Plugins";
import { errorPrefix } from "./Core/Utils/Constants";
export class Engine {
    constructor() {
        this._configs = new Map();
        this._domArray = [];
        this._eventDispatcher = new EventDispatcher();
        this._initialized = false;
        this._loader = new Loader(this);
        this.plugins = new Plugins(this);
    }
    get configs() {
        const res = {};
        for (const [name, config] of this._configs) {
            res[name] = config;
        }
        return res;
    }
    get version() {
        return "2.10.1";
    }
    addConfig(nameOrConfig, config) {
        if (typeof nameOrConfig === "string") {
            if (config) {
                config.name = nameOrConfig;
                this._configs.set(nameOrConfig, config);
            }
        }
        else {
            this._configs.set(nameOrConfig.name ?? "default", nameOrConfig);
        }
    }
    addEventListener(type, listener) {
        this._eventDispatcher.addEventListener(type, listener);
    }
    async addInteractor(name, interactorInitializer) {
        this.plugins.addInteractor(name, interactorInitializer);
        await this.refresh();
    }
    async addMover(name, moverInitializer) {
        this.plugins.addParticleMover(name, moverInitializer);
        await this.refresh();
    }
    async addParticleUpdater(name, updaterInitializer) {
        this.plugins.addParticleUpdater(name, updaterInitializer);
        await this.refresh();
    }
    async addPathGenerator(name, generator) {
        this.plugins.addPathGenerator(name, generator);
        await this.refresh();
    }
    async addPlugin(plugin) {
        this.plugins.addPlugin(plugin);
        await this.refresh();
    }
    async addPreset(preset, options, override = false) {
        this.plugins.addPreset(preset, options, override);
        await this.refresh();
    }
    async addShape(shape, drawer, init, afterEffect, destroy) {
        let customDrawer;
        if (typeof drawer === "function") {
            customDrawer = {
                afterEffect: afterEffect,
                destroy: destroy,
                draw: drawer,
                init: init,
            };
        }
        else {
            customDrawer = drawer;
        }
        this.plugins.addShapeDrawer(shape, customDrawer);
        await this.refresh();
    }
    dispatchEvent(type, args) {
        this._eventDispatcher.dispatchEvent(type, args);
    }
    dom() {
        return this._domArray;
    }
    domItem(index) {
        const dom = this.dom(), item = dom[index];
        if (!item || item.destroyed) {
            dom.splice(index, 1);
            return;
        }
        return item;
    }
    init() {
        if (this._initialized) {
            return;
        }
        this._initialized = true;
    }
    async load(tagId, options) {
        return this._loader.load(tagId, options);
    }
    async loadFromArray(tagId, options, index) {
        return this._loader.load(tagId, options, index);
    }
    async loadJSON(tagId, pathConfigJson, index) {
        return this._loader.loadJSON(tagId, pathConfigJson, index);
    }
    async refresh() {
        this.dom().forEach((t) => t.refresh());
    }
    removeEventListener(type, listener) {
        this._eventDispatcher.removeEventListener(type, listener);
    }
    async set(id, element, options) {
        return this._loader.set(id, element, options);
    }
    async setJSON(id, element, pathConfigJson, index) {
        return this._loader.setJSON(id, element, pathConfigJson, index);
    }
    setOnClickHandler(callback) {
        const dom = this.dom();
        if (!dom.length) {
            throw new Error(`${errorPrefix} can only set click handlers after calling tsParticles.load() or tsParticles.loadJSON()`);
        }
        for (const domItem of dom) {
            domItem.addClickHandler(callback);
        }
    }
}
