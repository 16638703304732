import { errorPrefix } from "./Constants";
function initDelta(value, fpsLimit = 60, smooth = false) {
    return {
        value,
        factor: smooth ? 60 / fpsLimit : (60 * value) / 1000,
    };
}
export class FrameManager {
    constructor(container) {
        this.container = container;
    }
    async nextFrame(timestamp) {
        try {
            const container = this.container;
            if (!container.smooth &&
                container.lastFrameTime !== undefined &&
                timestamp < container.lastFrameTime + 1000 / container.fpsLimit) {
                container.draw(false);
                return;
            }
            container.lastFrameTime ??= timestamp;
            const delta = initDelta(timestamp - container.lastFrameTime, container.fpsLimit, container.smooth);
            container.addLifeTime(delta.value);
            container.lastFrameTime = timestamp;
            if (delta.value > 1000) {
                container.draw(false);
                return;
            }
            await container.particles.draw(delta);
            if (!container.alive()) {
                container.destroy();
                return;
            }
            if (container.getAnimationStatus()) {
                container.draw(false);
            }
        }
        catch (e) {
            console.error(`${errorPrefix} in animation loop`, e);
        }
    }
}
